import { Brand } from '@hertz/odinui-web'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Contract } from '@typings/api/contract.types'
import { ContractState } from '@typings/store/contractState.types'

import api from '../../services/api'
import getErrorCode from '../../services/getErrorCode'

type ContractFetchBody = {
  referrer: string
  uuid: string
}

export const fetchContract = createAsyncThunk('contract/get', async (body: ContractFetchBody | null, thunkAPI) => {
  try {
    const response = await api.post<Contract>({
      url: '/terms/verify',
      data: body,
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue(getErrorCode(error))
  }
})

const initialState: ContractState = {
  brand: 'hertz',
  contractLoadingState: 'idle',
}

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    resetContractLoadingState: (state) => {
      state.contractLoadingState = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContract.pending, (state) => {
        state.contractLoadingState = 'pending'
      })
      .addCase(fetchContract.fulfilled, (state, action) => {
        const contract = action.payload
        if (contract?.agreement?.voidedRaIndicator) {
          state.contractLoadingState = 'voided'
        } else if (contract?.creditCardMissing) {
          state.contract = contract
          state.contractLoadingState = 'missingCreditCard'
        } else {
          state.brand = (contract?.agreement?.brand?.toLowerCase() as Brand) || 'hertz'
          state.contractLoadingState = 'succeeded'
          state.contract = contract
        }
      })
      .addCase(fetchContract.rejected, (state) => {
        state.contractLoadingState = 'failed'
      })
  },
})

export const { resetContractLoadingState } = contractSlice.actions
export const contractReducer = contractSlice.reducer
