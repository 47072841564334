import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { InsuranceDetails } from '@typings/api/insuranceDetails.types'
import { InsurancesState } from '@typings/store/insurancesState.types'

import createQueryParams from '@utils/createQueryParams'

import api from '../../services/api'
import getErrorCode from '../../services/getErrorCode'

const initialState: InsurancesState = {
  insurancesLoadingState: 'idle',
}

export const fetchInsuranceDetails = createAsyncThunk('insurances/getAll', async (locale: string, thunkAPI) => {
  try {
    const queryParams = createQueryParams({ lang: locale })
    const response = await api.get<InsuranceDetails>({
      url: `/terms/insurances${queryParams}`,
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue(getErrorCode(error))
  }
})

export const insurancesSlice = createSlice({
  name: 'insurances',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInsuranceDetails.pending, (state) => {
        state.insurancesLoadingState = 'pending'
      })
      .addCase(fetchInsuranceDetails.fulfilled, (state, action) => {
        state.insurancesLoadingState = 'succeeded'
        state.insuranceDetails = action.payload
      })
      .addCase(fetchInsuranceDetails.rejected, (state) => {
        state.insurancesLoadingState = 'failed'
      })
  },
})

export const insurancesReducer = insurancesSlice.reducer
