import axios from 'axios'
import type { AxiosProgressEvent, AxiosRequestConfig } from 'axios'

import { CustomProgressEvent } from './types/customProgressEvent.types'

const baseUrl = '/api'
const DEFAULT_HEADERS = { 'Content-Type': 'application/json' }

export type ApiOptions = AxiosRequestConfig & {
  formData?: FormData
  progressEvent?: (progress: CustomProgressEvent) => void
}

const convertToAxiosProgressEvent = (progress: CustomProgressEvent): AxiosProgressEvent => ({
  loaded: progress.loaded,
  total: progress.total,
  bytes: 0,
})

async function sendRequest<T>(
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  options: ApiOptions
): Promise<{ data: T; status: number }> {
  const config: AxiosRequestConfig = {
    method,
    ...options,
    url: `${baseUrl}${options.url}`,
    data: options.data || {},
    headers: { ...DEFAULT_HEADERS, ...options.headers },
  }

  if (options.formData) {
    config.data = options.formData
  }

  if (options.progressEvent) {
    config.onUploadProgress = (progress) => {
      const customEvent: CustomProgressEvent = {
        loaded: progress.loaded,
        total: progress.total,
        bytes: progress.bytes,
      }
      if (options.progressEvent) {
        options.progressEvent(convertToAxiosProgressEvent(customEvent))
      }
    }
  }

  const response = await axios(config)
  return { data: response.data as T, status: response.status }
}

/**
 * Base URL is set as `/api`.
 */
const api = {
  async get<T>(options: ApiOptions): Promise<{ data: T; status: number }> {
    return sendRequest<T>('GET', options)
  },
  async post<T>(options: ApiOptions): Promise<{ data: T; status: number }> {
    return sendRequest<T>('POST', options)
  },
  async delete<T>(options: ApiOptions): Promise<{ data: T; status: number }> {
    return sendRequest<T>('DELETE', options)
  },
  async put<T>(options: ApiOptions): Promise<{ data: T; status: number }> {
    return sendRequest<T>('PUT', options)
  },
}

export default api
