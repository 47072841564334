import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { EltonGetConsent } from '@typings/api/eltonGetConsent.types'
import { EltonPostConsent } from '@typings/api/eltonPostConsent.types'
import { EltonConsentState } from '@typings/store/eltonConsentState.types'

import api from '../../services/api'
import getErrorCode from '../../services/getErrorCode'

type EltonConsentUpdateBody = {
  consent: boolean
  redirect: boolean
}

export const fetchEltonConsent = createAsyncThunk('eltonConsent/get', async (_, thunkAPI) => {
  try {
    const response = await api.get<EltonGetConsent>({
      url: '/elton/consent',
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue(getErrorCode(error))
  }
})

export const updateEltonConsent = createAsyncThunk(
  'eltonConsent/update',
  async (body: EltonConsentUpdateBody, thunkAPI) => {
    try {
      const response = await api.post<EltonPostConsent>({
        url: '/elton/consent',
        data: body,
      })
      if (response.data.code === 200) {
        return response.data
      }
      return thunkAPI.rejectWithValue(getErrorCode(response.data.code))
    } catch (error) {
      return thunkAPI.rejectWithValue(getErrorCode(error))
    }
  }
)

const initialState: EltonConsentState = {
  eltonState: { consent: false },
  eltonFetchedConsentLoadingState: 'idle',
  eltonPostedConsentLoadingState: 'idle',
}

export const eltonConsentSlice = createSlice({
  name: 'eltonConsent',
  initialState,
  reducers: {
    resetEltonPostLoadingState: (store) => {
      store.eltonPostedConsentLoadingState = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEltonConsent.pending, (state) => {
        state.eltonFetchedConsentLoadingState = 'pending'
        state.errorCode = undefined
      })
      .addCase(fetchEltonConsent.fulfilled, (state, action) => {
        state.eltonFetchedConsentLoadingState = 'succeeded'
        state.eltonState.consent = action.payload?.consent
        state.eltonState.deepLink = action.payload?.deepLink
      })
      .addCase(fetchEltonConsent.rejected, (state) => {
        state.eltonFetchedConsentLoadingState = 'failed'
      })
      .addCase(updateEltonConsent.pending, (state) => {
        state.eltonPostedConsentLoadingState = 'pending'
        state.errorCode = undefined
      })
      .addCase(updateEltonConsent.fulfilled, (state, action) => {
        state.eltonPostedConsentLoadingState = 'succeeded'
        state.eltonState.deepLink = action.payload.deepLink
        state.eltonState.consent = !state.eltonState.consent
      })
      .addCase(updateEltonConsent.rejected, (state, action) => {
        state.eltonPostedConsentLoadingState = 'failed'
        if (action.payload) {
          state.errorCode = action.payload as number
        }
      })
  },
})

export const { resetEltonPostLoadingState } = eltonConsentSlice.actions

export const eltonConsentReducer = eltonConsentSlice.reducer
