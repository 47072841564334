import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Locale } from '@typings/localization/locale.types'
import { LocaleState } from '@typings/store/localState.types'
import { setOneTrustLanguage } from '@utils/setOneTrustLanguage'

const initialState: LocaleState = {
  locale: { language: 'en', country: 'us' },
}

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    changeLocale: (store, action: PayloadAction<Locale>) => {
      store.locale = action.payload
      setOneTrustLanguage(action.payload)
    },
  },
})

export const { changeLocale } = localeSlice.actions

export const localeReducer = localeSlice.reducer
