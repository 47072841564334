import { createSlice } from '@reduxjs/toolkit'
import { UiState } from '@typings/store/uiState.types'

const initialState: UiState = {
  userClosedOnboardingBanner: false,
  userClosedEndRentalBanner: false,
  userClosedInfoBanner: false,
  mobileMenuOpen: false,
}

export const uiStateSlice = createSlice({
  name: 'uiState',
  initialState,
  reducers: {
    toggleMobileMenu: (store) => {
      store.mobileMenuOpen = !store.mobileMenuOpen
    },
    closeOnboardingBanner: (store) => {
      store.userClosedOnboardingBanner = true
    },
    closeEndRentalBanner: (store) => {
      store.userClosedEndRentalBanner = true
    },
    closeInfoBanner: (store) => {
      store.userClosedInfoBanner = true
    },
  },
})

export const { toggleMobileMenu, closeOnboardingBanner, closeEndRentalBanner, closeInfoBanner } = uiStateSlice.actions
export const uiStateReducer = uiStateSlice.reducer
