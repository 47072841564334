import { Locale } from '@typings/localization/locale.types'

import { mapLocaleToString } from './localeHelper'

type OneTrustWindow = {
  OneTrust: {
    changeLanguage: (locale: string) => void
  }
} & Window &
  typeof globalThis

export const setOneTrustLanguage = (locale: Locale) => {
  const stringLocale = mapLocaleToString(locale)
  document.documentElement.setAttribute('lang', stringLocale)
  const oneTrust = (window as OneTrustWindow).OneTrust
  if (oneTrust) {
    oneTrust.changeLanguage(stringLocale)
  }
}
