import { SearchOption } from '@features/invoices/constants/searchOption'
import { InvoiceStep } from '@features/invoices/types/invoiceStep.types'
import { SearchForm } from '@features/invoices/types/searchForm.types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Invoice } from '@typings/api/invoice.types'
import { InvoicesState } from '@typings/store/invoicesState'

const initialState: InvoicesState = {
  invoiceStep: 'search',
  searchOption: SearchOption.raNoOrResId,
}

export const invoicesSlice = createSlice({
  name: 'invoicesState',
  initialState,
  reducers: {
    setStep: (store, action: PayloadAction<InvoiceStep>) => {
      store.invoiceStep = action.payload
    },
    saveFormState: (store, action: PayloadAction<SearchForm>) => {
      store.searchForm = action.payload
    },
    saveFormResult: (store, action: PayloadAction<Invoice[]>) => {
      store.invoices = action.payload
    },
    saveSearchOption: (store, action: PayloadAction<SearchOption>) => {
      store.searchOption = action.payload
      store.searchForm = undefined
    },
    setInvoiceError: (store, action: PayloadAction<boolean>) => {
      store.error = action.payload
    },
  },
})

export const { setStep, saveFormState, saveFormResult, saveSearchOption, setInvoiceError } = invoicesSlice.actions
export const invoicesReducer = invoicesSlice.reducer
