import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CreditCardState } from '@typings/store/creditCard.types'

const initialState: CreditCardState = {
  ongoingPaymentSession: false,
}

export const creditCardSlice = createSlice({
  name: 'creditCardState',
  initialState,
  reducers: {
    setOngoingPaymentSession: (store, action: PayloadAction<boolean>) => {
      store.ongoingPaymentSession = action.payload
    },
  },
})

export const { setOngoingPaymentSession } = creditCardSlice.actions
export const creditCardReducer = creditCardSlice.reducer
