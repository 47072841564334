import { Brand } from '@hertz/odinui-web'

const getBrandFavicon = (brand: Brand) => {
  switch (brand) {
    case 'dollar':
      return 'https://resources.drivehertz.net/favicons/dollar.ico'
    case 'thrifty':
      return 'https://resources.drivehertz.net/favicons/thrifty.ico'
    default:
      return 'https://resources.drivehertz.net/favicons/hertz.ico'
  }
}

export default getBrandFavicon
