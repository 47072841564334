import { Brand } from '@hertz/odinui-web'
import { ThemeProvider } from '@hertz/storybook'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'

import './src/style/global.css'
import { loadBrandFromLocalStorage } from './src/store/localStorageMiddleware'
import store from './src/store/store'
import getBrandFavicon from './src/utils/getBrandFavicon'

export const wrapRootElement = ({ element }) => {
  const brandFromStorage: Brand = loadBrandFromLocalStorage().brand
  return (
    <ThemeProvider brand="Hertz">
      <Helmet>
        <title>Hertz Mypage</title>
        <link rel="icon" type="image/x-icon" href={getBrandFavicon(brandFromStorage)} />
      </Helmet>
      <Provider store={store}>{element}</Provider>
    </ThemeProvider>
  )
}
