import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { UserImages } from '@typings/api/userImages.types'
import { UserPhotosState as UserImagesState } from '@typings/store/userImagesState.types'

import api from '../../services/api'
import getErrorCode from '../../services/getErrorCode'

const initialState: UserImagesState = {
  userImagesLoadingState: 'idle',
}

export const fetchUserImages = createAsyncThunk('userImages/getAll', async (_, thunkAPI) => {
  try {
    const response = await api.get<UserImages>({
      url: '/file-archive/files/myimages',
    })
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue(getErrorCode(error))
  }
})

export const userImagesSlice = createSlice({
  name: 'userImages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserImages.pending, (state) => {
        state.userImages = undefined
        state.userImagesLoadingState = 'pending'
      })
      .addCase(fetchUserImages.fulfilled, (state, action) => {
        state.userImagesLoadingState = 'succeeded'
        state.userImages = action.payload
      })
      .addCase(fetchUserImages.rejected, (state) => {
        state.userImagesLoadingState = 'failed'
      })
  },
})

export const userImagesReducer = userImagesSlice.reducer
