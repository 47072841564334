import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DamageInfo } from '@typings/api/damageInfo.types'
import { CarDamagesState } from '@typings/store/carDamagesState.types'

import createQueryParams from '@utils/createQueryParams'

import api from '../../services/api'
import getErrorCode from '../../services/getErrorCode'

type DamagesQueryParams = {
  language: string
  checkoutDate: number
}

const initialState: CarDamagesState = {
  carDamagesLoadingState: 'idle',
}

export const fetchCarDamages = createAsyncThunk(
  'carDamages/getAll',
  async ({ language, checkoutDate }: DamagesQueryParams, thunkAPI) => {
    try {
      const queryParams = createQueryParams({ lang: language, outDate: checkoutDate })
      const response = await api.get<DamageInfo[]>({
        url: `/damages/regno${queryParams}`,
      })
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(getErrorCode(error))
    }
  }
)

export const carDamagesSlice = createSlice({
  name: 'carDamages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCarDamages.pending, (state) => {
        state.damages = undefined
        state.carDamagesLoadingState = 'pending'
      })
      .addCase(fetchCarDamages.fulfilled, (state, action) => {
        state.carDamagesLoadingState = 'succeeded'
        state.damages = action.payload
      })
      .addCase(fetchCarDamages.rejected, (state) => {
        state.carDamagesLoadingState = 'failed'
      })
  },
})

export const carDamagesReducer = carDamagesSlice.reducer
