import { isAxiosError } from 'axios'

const getErrorCode = (error: unknown): number => {
  const DEFAULT_ERROR_CODE = 500

  if (isAxiosError(error) && error.response && typeof error.response.status === 'number') {
    return error.response.status
  }

  return DEFAULT_ERROR_CODE
}

export default getErrorCode
