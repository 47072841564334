import { Country } from '@typings/localization/country.types'
import { Language } from '@typings/localization/language.types'
import { Locale } from '@typings/localization/locale.types'

export const getLocaleFromLocalstorage = () => {
  if (typeof window !== 'undefined') {
    const storedLocale = localStorage.getItem('locale')
    if (storedLocale) {
      const locale: Locale = {
        language: storedLocale?.split('-')[0] as Language,
        country: storedLocale?.split('-')[1] as Country,
      }
      return locale
    }
  }
}

export const mapLocaleToString = (locale: Locale) => {
  return `${locale.language || 'en'}-${locale.country || 'us'}`
}

export const mapStringToLocale = (locale: string) => {
  return {
    language: (locale?.split('-')[0] as Language) || 'en',
    country: (locale?.split('-')[1] as Country) || 'us',
  }
}
